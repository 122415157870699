import * as React from 'react'
import { useTranslation } from 'react-i18next'
import NotLogged from 'components/NotLogged'
import {
  getPreferences
} from 'store/reducers/preference.thunks'
import { useDispatch, useSelector } from 'react-redux'
import './styles.sass'
import {Tab, Box } from '@mui/material'
import {TabPanel, TabList, TabContext} from '@mui/lab'
import AssetTreatmentPortal from '../AssetTreatment/AssetTreatmentPortal'
import { axiosInstance } from 'services/api'
import AssessmentPortal from 'views/Assessment/AssessmentPortal'
import UserStore from 'services/userLocalStorage'
import { useQuery } from 'hooks/useQuery'
import IncidentsSelfService from 'views/IncidentsSelfService'

function SelfService({ match, ...props }) {
    const { t } = useTranslation()
    const { auth, geral = {} } = useSelector((store) => store)
    const reduxDispatch = useDispatch()
    const [value, setValue] = React.useState('one');
    const [canCreateDiagnosis, setCanCreateDiagnosis] = React.useState(false)
    const [canCreateAssets, setCanCreateAssets] = React.useState(false)
    const [canCreateIncidents, setCanCreateIncidents] = React.useState(false)
    const query = useQuery();
    
   let token = ''
   if(auth.signinToken == null){
       token = UserStore.getToken()
    }else{
        token = auth.signinToken
    }
    
    React.useEffect(() => {
        setValue(query.get("tab") ?? 'one');
    
        if (auth.isAuthenticated && geral.language) {
          reduxDispatch(getPreferences(geral.language))
        }
        
        // eslint-disable-next-line
      }, [reduxDispatch, geral.language])

    React.useEffect(() =>{
      loadPermission()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function loadPermission() {
    const requestOptions = {
      method: 'GET',
      url: `/selfservice/${window.endPoints.api.portalID}/hasPermission`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    }

    axiosInstance(requestOptions)
      .then((res) => {
        setCanCreateDiagnosis(res.data.canCreateDiagnosis)
        setCanCreateAssets(res.data.canCreateAsset)
        setCanCreateIncidents(res.data.canCreateIncidents)
      })
      .catch((err) => {
        console.log(err)
        setCanCreateDiagnosis(false)
        setCanCreateAssets(false)
        setCanCreateIncidents(false)
      })
  }

    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };
    
    if (!auth.isAuthenticated) {
        return (
          <section>
            {/* <header className="pvt-content-header custom">
              <h1 className="pvt-content-title custom">{t('preferences.title')}</h1>
            </header> */}
            <NotLogged />
          </section>
        )
    }

    return (

        <section>
          <header className="pvt-content-header custom">
            <h1 className="pvt-content-title custom">{t('routes.label.selfService')}</h1>
            <p className="pvt-content-desc custom">
              {t('selfService.content')}
            </p>
          </header>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                  {canCreateAssets ?
                  <Tab default label={t('label.self.service.asset.create')} value="0" />
                  : null}
                  {canCreateDiagnosis ?
                  <Tab label={t('label.self.service.diagnosis.create')} value="1" />
                  : null}
                  {canCreateIncidents ?
                  <Tab label={t('label.self.service.incident.label')} value="2" />
                  : null}
                </TabList>
              </Box>
              <TabPanel value="0">
                <AssetTreatmentPortal />
              </TabPanel>
              <TabPanel value="1">
                <AssessmentPortal />
              </TabPanel>
              <TabPanel value="2">
                <IncidentsSelfService />
              </TabPanel>    
            </TabContext>
          </Box>
        </section>
      )

      
}

export default SelfService
