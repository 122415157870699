import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
// routing stuff
import { useLocation, Link } from 'react-router-dom'
import routes from 'routes/routes'

import { TYPES } from '../../store/reducers/menu.reducer';
import UserStore from 'services/userLocalStorage'
import './styles.sass'

function Navigation() {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const location = useLocation();

	const { auth, menu, geral } = useSelector(store => store);
	const authApp = auth?.user.authApp || false;
	const defaultClass = 'pvt-navigation custom';
	const showMenuClass = 'pvt-navivation__show-menu';
	const [classes, setClasses] = useState(defaultClass);
	const [authorized, setAuthorized] = useState(false)
	const hideMenu = () => dispatch({ type: TYPES.HIDE_MENU });
	const scrollToTop = () => {
		if (location.pathname !== '/auth/verification')
			window.scrollTo(0, 0)
	};

	const keyByPortal = (keyName) => {
		const splittedPathName = window.location.pathname.replace(`/portal/`, "").split("/");
		const portalId = splittedPathName && splittedPathName.length > 0 ? splittedPathName[0] : "";
		return `${keyName}_${portalId}`;
	  }

	const authorization = () => {
		const keyAuth = keyByPortal(UserStore.LOCAL_STORAGE_ISAUTHORIZATION);
		const authed = localStorage.getItem(keyAuth)
		if(authed !== "null" || authed !== null){
			setAuthorized(authed)
		}
	}


	useEffect(() => {
		hideMenu();
		authorization();
		scrollToTop();		
	}, [location]);
	

	useEffect(() => {
		const _classes = menu.visible ? `${defaultClass} ${showMenuClass}` : defaultClass;
		setClasses(_classes);
	}, [menu,authorized]);

	

	const routesFilter = useMemo(() => {
		return routes.filter(route => {	
			const {portalOpenBankingOk, showHomePage } = geral.portalConfig
			const routeBlock = []
			
			// Enquanto não carregou informações do portal 
			// Não exibe menus para evitar efeito mostra apaga
			if (showHomePage === undefined) return false

			if (!showHomePage) routeBlock.push('/')
			if (!portalOpenBankingOk) routeBlock.push('/data-share')
			let routeShow = ""
			if(route.name === "routes.label.selfService"){
				if(authorized === "true" || authorized === true){
					routeShow = true
				}
			}else{
				
				 routeShow = !(routeBlock.findIndex(r => r === route.path) >= 0)
			}
			return routeShow
		} )
	}, [routes, geral.portalConfig,authorized])

	return (
		<nav className={classes}>
			<ul>
				{routesFilter.map(({ name, path }) => (
					<React.Fragment key={name}>
						{name !== 'routes.label.yourPersonalData' || (geral.portalConfig?.allowUpdate === true && !authApp) ?
							<li className={location.pathname === path ? 'active' : null}>
								<Link to={path}>{t(name)}</Link>
							</li>
							: null}
					</React.Fragment>
				))}
			</ul>
		</nav>
	)

}

export default Navigation