import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.sass'

import { Row } from 'components/Layout'
import DropdownMenu from 'components/DropdownMenu'

// store
import { useSelector, useDispatch } from 'react-redux'

// services
import { signOut, alternativeSignIn } from 'store/reducers/auth.thunks'

import { getLanguageFromLocalStorage, languageUpdate } from 'store/reducers/geral.thunks'

// routing stuff
import { Link, useHistory, useLocation } from 'react-router-dom'

import {
  languages as availableLanguages,
  getLanguageObjectByValue,
  getLanguageCodeByValue,
  getLanguageObjectByCode,
  lsc
} from 'services/availableLanguages'


import {
  getPersonalData,
} from 'store/reducers/personal-data.thunks';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function User() {
  const { t, i18n } = useTranslation()
  const { auth = {}, personalData = {}, geral = {} } = useSelector((store) => store)
  const { portalConfig } = geral
  const dispatch = useDispatch()

  let query = useQuery();

  const history = useHistory();

  const [language, setLanguage] = useState(getLanguageObjectByValue(geral.language || i18n.language));
  const _token = query.get("token");
  const _empty = query.get("empty");

  useEffect(() => {

    if (auth.isAuthenticated) {
      dispatch(getPersonalData());
    }

    /* marison - removi essa verificacao pois nao tem logout, se vier token na url ele tem que trocar tudo */

    if (_empty && (_empty === "true" || _empty === true)) {

      // Força limpeza de login (alinhado com RCI)
      logout();

    } else if (_token) {
      const subStringToken = _token.split('.')[1];

      const decodeToken = atob(subStringToken);

      const parsedToken = JSON.parse(decodeToken);

      const _userInfo = JSON.parse(parsedToken.sub);
      dispatch(alternativeSignIn(_token, _userInfo.name || ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_token]);

  useEffect(() => {
    let myLang = "pt"
    if (geral.portalConfig?.languages) {
      //verifica se a language atual é suportada
      const lang = i18n.language;
      const currentLangCode = getLanguageCodeByValue(lang)
      myLang = lsc(lang)
      if (!currentLangCode || !geral.portalConfig.languages?.includes(currentLangCode)) {
        //se não é suportado, pega o primeiro da lista
        const newLangObj = getLanguageObjectByCode(geral.portalConfig.languages[0])
        if (newLangObj) {
          myLang = newLangObj.value
          i18n.changeLanguage(myLang);
          dispatch(languageUpdate(newLangObj.value));
        }
      }
    }
    //cores
    let template = null
    if (geral.portalConfig?.templates) {
      template = geral.portalConfig?.templates[myLang]
    }
    if (template) {
      if (template.colorBackground) {
        document.documentElement.style.setProperty('--bgColor', template.colorBackground)
      }
      if (template.colorFont) {
        document.documentElement.style.setProperty('--gray', template.colorFont)
        document.documentElement.style.setProperty('--dk-surface', template.colorFont)
      }
    }

  }, [dispatch, geral.portalConfig, i18n]);

  useEffect(() => {
    const lang = i18n.language;
    if (!geral.language) {
      setLanguage(getLanguageObjectByValue(lang))
      dispatch(getLanguageFromLocalStorage(lang));
    } else {
      setLanguage(getLanguageObjectByValue(geral.language))
    }
  }, [i18n.language, geral.language, dispatch]);

  function logout() {
    dispatch(signOut(() => {
      history.push({
        pathname: '/signin',
        forceSignOut: true,
      })
    }))
  }

  function handleChangeLanguage(languageObj) {
    setLanguage(languageObj);

    i18n.changeLanguage(languageObj.value);
    dispatch(languageUpdate(languageObj.value));

  }

  const myLanguages = availableLanguages.filter((e) => geral.portalConfig?.languages?.includes(e.code))
  // const myLanguages = availableLanguages

  const showLanguageMenu = (myLanguages && myLanguages.length > 1)

  if (!auth.isAuthenticated) {
    return (
      <Row className='unauthenticated-actions' position={['y-center', 'x-center']}>
        {showLanguageMenu ?
          <DropdownMenu
            label={t('idiom.select')}
            items={myLanguages}
            selectedValue={language}
            handleChange={handleChangeLanguage}
          />
          : null
        }
        <Row className='sign-row' position={['y-center', 'x-center']}>
          <Link
            to="/signin"
            className="pvt-user-btn pvt-user-signin-access custom"
          >
            {t('notLogged.signIn')}
          </Link>
          {
            geral.portalConfig.authType ?
              portalConfig.externalRegisterURL ?
                <a href={portalConfig.externalRegisterURL} rel="noopener" className="pvt-user-btn pvt-user-signin pvt-btn custom pvt-btn-confirm" target="_blank">
                  {t('notLogged.signup')}
                </a>
                :
                <Link to="/signup" className="pvt-user-btn pvt-user-signin pvt-btn custom pvt-btn-confirm">
                  {t('notLogged.signup')}
                </Link>
              : ''
          }
        </Row>

      </Row >
    )
  }

  return (
    <Row className='user-actions' position={['y-center', 'x-center']}>
      {showLanguageMenu ?
        <DropdownMenu
          label={t('idiom.select')}
          items={myLanguages}
          selectedValue={language}
          handleChange={handleChangeLanguage}
        />
        : null
      }
      <div className="pvt-user custom ">
        <i className="far fa-user" aria-hidden="true"></i>
        <h5>{auth?.user?.name || auth.user?.email || personalData?.person?.name || personalData?.person?.email || ''}</h5>
        {/* <img src={`https://unavatar.now.sh/thommorais@gmail.com`} alt="avatar" /> */}
        {
          !auth?.user?.authApp ?
            <>
              <button type="button" className="pvt-logout" onClick={logout}>
                {t('button.label.exit')}
              </button>
            </>
            : null
        }
      </div>
    </Row>
  )
}

export default User
