import React, { useMemo, useState } from 'react';

import { Row, Col } from 'components/Layout';
import Button from 'components/Button';
import Accordion from 'components/Accordion';
import { useTranslation } from 'react-i18next';

import { investmentDataLabel, registrationDataLabel, transactionalDataLabel } from './components/staticLabels';
import SectionData from './components/SectionData'
import DeadLine from './components/DeadLine';
import Status, { statusList } from './components/Status';
import { Link } from '@mui/material';
import DataShareStages from './components/DataShareStages';

const awaiting = statusList[0]
const authorised = statusList[1]
const reject = statusList[2]
const finished = statusList[3]
const expired = statusList[4]

const DataShareDetailBody = ({
  consentRequest: {
    customerId,
    originName,
    expiration,
    document,
    status,
    statusUpdateDateTime,
    termsAndConditions,
    permissionsStatus,
    setPermissionsStatus,
    statusAuthorizedDateTime,
    statusRejectedDateTime
  },
  handleClickButtonsConsent
}) => {
  const { t } = useTranslation();
  const { registrationData, financingData, investmentData } = permissionsStatus || {}
  const [modal, setModal] = useState(false);

  const showButtons = useMemo(() => {
    if (status !== undefined && status !== reject && status !== finished && status !== expired) return true

    return false
  }, [status])

  const showConfirmButton = useMemo(() => {
    return (status === awaiting) 
  }, [status])

  const disabledConfirmButton = useMemo(() => {
    return registrationData !== authorised
  }, [registrationData])

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date).replace(',', '')
  }

  const consentDate = useMemo(() => {
    if (statusUpdateDateTime === undefined || !statusUpdateDateTime) return ""

    let date = new Date()
    if (status !== awaiting) date = new Date(statusUpdateDateTime)

    return formatDate(date)
  }, [status, statusUpdateDateTime])

  const consentDateTimeFomate = (dateTime) => {
    if (dateTime === undefined || !dateTime) return ""
    let date = new Date(dateTime)
    return formatDate(date)
  }

  const consentDetails = [
    [
      { title: t('label.datashare.receiver'), value: originName },
      { title: 'Status', value: <Status status={status} /> },
      { title: t('label.datashare.deadline'), value: expiration ? <DeadLine deadLine={expiration} /> : t('label.datashare.deadline.indefinite') },
    ],
    [
      { title: t('label.datashare.customerCode'), value: customerId },
      { title: t('label.datashare.clientDocument'), value: document }
    ],
    [
      { title: t('label.datashare.consentDate'), value: consentDate },
      { title: t('label.datashare.consentDate.AUTHORIZED'), value: consentDateTimeFomate(statusAuthorizedDateTime), disable: !statusAuthorizedDateTime },
      { title: t('label.datashare.consentDate.REJECTED'), value: consentDateTimeFomate(statusRejectedDateTime), disable: !statusRejectedDateTime },
    ]
  ]

  const handleChangeConsentPermission = e => {
    const { target: { checked, name } } = e;

    var newRegistrationData = registrationData;
    var newFinancingData = financingData;
    var newInvestmentData = investmentData;

    if (name === 'financingData') {
      if (checked) {
        newFinancingData = authorised
      } else {
        newFinancingData = reject
      }
    } else if (name === 'investmentData') {
      if (checked) {
        newInvestmentData = authorised
      } else {
        newInvestmentData = reject
      }
    } else if (name === 'registrationData') {
      if (checked) {
        newRegistrationData = authorised
      } else {
        newRegistrationData = reject
        newFinancingData = newFinancingData ? reject : null
        newInvestmentData = newInvestmentData ? reject : null
      }
    }

    setPermissionsStatus({
      registrationData: newRegistrationData,
      financingData: newFinancingData,
      investmentData: newInvestmentData
    })
  }

  return (
    <section className="pvt-request-detail pvt-card pvt-txt-card">
      <Line lineData={consentDetails[0]} />
      <Line lineData={consentDetails[1]} style={{ marginTop: 16 }} />
      <Line lineData={consentDetails[2]} style={{ marginTop: 16 }} />

      <div>
        <AccordionData
          name="registrationData"
          label={t('label.datashare.detail.registrationData')}
          setCheckBoxValue={handleChangeConsentPermission}
          sectionsData={[registrationDataLabel]}
          isTitle
        />

        {!investmentData && !financingData && <Divider marginBottom="16px" />}

        {investmentData && (
          <>
            <AccordionData
              name="investmentData"
              label={t("label.datashare.detail.investment")}
              setCheckBoxValue={handleChangeConsentPermission}
              sectionsData={[investmentDataLabel]}
              isTitle
            />
            {!financingData && <Divider marginBottom="16px" />}
          </>
        )}

        {financingData && (
          <>
            <AccordionData
              name="financingData"
              label={t("label.datashare.detail.financing")}
              setCheckBoxValue={handleChangeConsentPermission}
              sectionsData={[transactionalDataLabel]}
              isTitle
            />
            <Divider marginBottom="16px" />
          </>
        )}

        <p style={{ maxWidth: '400px' }}>
          Cliente, se você vier a contratar novas operações de crédito,
          câmbio ou operações de investimentos durante a vigência do compartilhamento,
          os dados destas novas operações também serão compartilhadas.
        </p>
      </div>

      <Link sx={{ float: 'right', color: 'var(--primaryColor)', cursor: 'pointer' }} onClick={() => setModal(true)}>Saiba mais sobre o Open Finance e suas fases</Link>

      <DataShareStages open={modal} onClose={() => setModal(false)} />


      {showButtons && (
        <div style={{ textAlign: 'center' }}>
          <Button
            type="cancel"
            label={t('button.label.reject')}
            onClick={() => handleClickButtonsConsent(reject)}
          />

          {showConfirmButton && (
            <Button
              disabled={disabledConfirmButton}
              type="primary"
              label={t('button.label.accept')}
              onClick={() => handleClickButtonsConsent(authorised)}
              style={{
                marginLeft: 8
              }}
            />
          )}
        </div>
      )}
    </section>
  )
}

function Divider({ marginBottom }) {
  return (
    <div
      style={{
        display: 'block',
        borderBottom: 'solid 1px rgba(0,0,0, 0.1)',
        marginBottom,
      }}
    />
  )
}

const AccordionData = (params) => {
  const {
    sectionsData,
    label,
    checkBoxValue,
    setCheckBoxValue,
    isTitle,
    style,
    checkboxDisabled,
    name
  } = params

  return (
    <Accordion
      header={
        <div>
          {checkBoxValue !== undefined && checkBoxValue !== null &&
            <input
              name={name}
              disabled={checkboxDisabled}
              type="checkbox"
              checked={checkBoxValue}
              onChange={setCheckBoxValue}
            />
          }
          <span style={{ fontWeight: isTitle && 900, marginLeft: 8 }}>
            {label}
          </span>
        </div>
      }
      style={style}
    >
      {sectionsData && sectionsData[0] &&
        sectionsData.map((sectionData, index) =>
          <SectionData key={`section_${String(index)}`} sectionData={sectionData} />
        )
      }
    </Accordion>
  )
}

const Line = ({ lineData, ...rest }) => {
  return (
    <Row {...rest}>
      {lineData.map(({ title, value, disable }) => (
        !disable &&
        <Col key={title} style={{ width: '33%' }}>
          <div><strong>{title}</strong></div>
          <div>{value}</div>
        </Col>
      ))}
    </Row>
  )
}

export default DataShareDetailBody