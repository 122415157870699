import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { signOut } from 'store/reducers/auth.thunks'
import { STATUS_CODES } from 'http'
import { isNumeric } from 'utils/helpers';

import UserStore from 'services/userLocalStorage'
// Creators
import { Creators as authCreators } from 'store/reducers/auth.reducer'

const SignInSsoCallback = () => {

  const { t } = useTranslation()
  const { tokenSso } = useParams();
  const history = useHistory();
  const dispatch = useDispatch()

  const logout = () => {
    dispatch(signOut(() => {
      history.push({
        pathname: '/signin',
        forceSignOut: true,
      })
    }))
  }

  const getTokenSsoObj = (encodeToken) => {
    try {
      const token = decodeURIComponent(encodeToken)
      const plaintext = atob(token)
      return JSON.parse(plaintext)
    } catch (error) {
      console.error('Error', error)
      return null
    }
  }

  useEffect(() => {

    if (isNumeric(tokenSso) && tokenSso >= 400 && tokenSso <= 599) {
      console.error(STATUS_CODES[tokenSso]);
      
      if (tokenSso === 404) {
        alert(t('signin.validation.error.sso.person.not.found'))
      } else {
        alert(t('signup.validation.error.sso.person.unauthorized'))
      }
      logout();
    } else {
      const tokenObj = getTokenSsoObj(tokenSso);

      if (tokenObj) {
        logout();
        const data = tokenObj;

        console.log(data)
        UserStore.storeToken(data.token)
        UserStore.storeEmailData(data.email)
        UserStore.storeUserData(data.name)
        
        UserStore.storeIsAuth(true)
        dispatch(authCreators.login({ name: data.name, email: data.email, company: data.company }))

        history.push('/');

      } else {
        logout();
      }
    }

  }, [])

  return (
    <div>{t('label.loading')} </div>
  );

}

export default SignInSsoCallback