import Button from 'components/Button/Button'
import Select from 'components/Form/CustomSelect/Select'
import Field from 'components/Form/Field/Field'
import Input from 'components/Form/Input/Input'
import Label from 'components/Form/Label/Label'
import Textarea from 'components/Form/Textarea/Textarea'
import { Loading } from 'components/Icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import Popup from 'reactjs-popup'
import { axiosInstance } from 'services/api'
import UserStore from 'services/userLocalStorage'

const formInitialValues = {
  name: '',
  numberHoldersInvolved: '',
  personalDataInvolved: '',
  rootCause: '',
  description: '',
}

const IncidentsSelfService = () => {
  const captchakey = process.env.REACT_APP_RECAPTCHA_KEY
  const { t, i18n } = useTranslation()
  const language = i18n.language === 'pt' ? 'pt-BR' : i18n.language

  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState()
  const [captchaValue, setCaptchaValue] = useState('')

  const [types, setTypes] = useState([])
  const [selectedType, setSelectedType] = useState(null)

  const [formValues, setFormValues] = useState(formInitialValues)

  const inputFile = useRef(null)

  const [fileData, setFileData] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileError, setFileError] = useState('')

  const handleUploadClick = (event) => {
    event.preventDefault()
    inputFile.current.click()
  }

  const changeFieldFile = (event) => {
    var myFile = event.target.files[0]

    const fileName = myFile.name.toLowerCase()
    const validFormat =
      fileName.endsWith('.pdf') || fileName.endsWith('.png') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')

    const validSize = myFile.size < 2000000 //2MB

    var errorMessage = ''
    if (!validFormat) {
      errorMessage = t('upload.file.error.invalidFormat')
    }
    if (!validSize) {
      errorMessage = errorMessage + ' ' + t('upload.file.error.fileTooLarge')
      setMessage(t('upload.file.error.fileTooLarge.message'))
    }
    setFileError(errorMessage)

    const hasError = !(validSize && validFormat)
    if (hasError) {
      setFileName('')
      setFileData('')
      return
    }
    const reader = new FileReader()

    reader.onload = () => {
      setFileName(myFile.name)
      setFileData(reader.result)
    }

    reader.readAsDataURL(myFile)
  }

  const handleChangeType = (value) => {
    setSelectedType(value)
  }

  const handleChangeInput = ({ target: { value, name } }) => {
    setFormValues((previousValues) => ({ ...previousValues, [name]: value }))
  }

  const handleSubmit = async () => {
    try {
      const token = UserStore.getToken()
      const config = { headers: { Authorization: `Bearer ${token}` } }

      const values = {
        ...formValues,
        typeEncryptedId: selectedType.value,
        fileData,
        fileName,
      }

      await axiosInstance.post(`incident?portalId=${window.endPoints.api.portalID}`, values, config)
      setMessage(t('Obrigado, seu registro de incidente foi recebido com sucesso!'))
      setFormValues(formInitialValues)
      setSelectedType(null)
      setFileData('')
      setFileName('')
    } catch (error) {
      console.error({ error })
      setMessage(t('genericError'))
    }
  }

  const getData = useCallback(async () => {
    setIsLoading(true)
    const token = UserStore.getToken()
    const config = { headers: { Authorization: `Bearer ${token}` } }

    try {
      const [{ data: incidentTypesData }] = await Promise.all([axiosInstance.get(`incident/types?portalId=${window.endPoints.api.portalID}`, config)])
      setTypes(incidentTypesData.map(({ encryptedId, name }) => ({ value: encryptedId, label: t(name) })))
    } catch (error) {
      console.error({ error })
      setMessage(t('genericError'))
    }
    setIsLoading(false)
  }, [t])

  useEffect(() => {
    getData()
  }, [getData])

  const isSubmitButtonDisabled =
    !formValues.name ||
    !formValues.description ||
    !formValues.numberHoldersInvolved ||
    !formValues.personalDataInvolved ||
    !selectedType ||
    !captchaValue

  if (isLoading) return <Loading />

  return (
    <section>
      <header className='pvt-content-header custom'>
        <h1
          style={{
            margin: '0 0 5px',
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'var(--txt-surface)',
          }}
        >
          {t('label.self.service.incident.create')}
        </h1>
        <p className='pvt-content-desc custom'>{t('label.self.service.incident.description')}</p>
      </header>

      <div className='pvt-card pvt-card-100'>
        <div className='dsar-form'>
          <Field>
            <Label label={t('label.self.service.incident.name')} relation='name' mandatory />
            <Input id='name' name='name' onChange={handleChangeInput} type='text' value={formValues.name} />
          </Field>
          <Field>
            <Label label={t('label.self.service.incident.type')} mandatory={true} />
            <Select
              name='typeEncryptedId'
              onChange={handleChangeType}
              isSearchable={false}
              options={types}
              value={selectedType}
            />
          </Field>
          <Field>
            <Label
              label={t('label.self.service.incident.numberHoldersInvolved')}
              relation='numberHoldersInvolved'
              mandatory
            />
            <Input
              id='numberHoldersInvolved'
              name='numberHoldersInvolved'
              onChange={handleChangeInput}
              value={formValues.numberHoldersInvolved}
              type='number'
            />
          </Field>
          <Field>
            <Label
              label={t('label.self.service.incident.personalDataInvolved')}
              relation='personalDataInvolved'
              mandatory
            />
            <Input
              id='personalDataInvolved'
              name='personalDataInvolved'
              onChange={handleChangeInput}
              value={formValues.personalDataInvolved}
              type='text'
              placeholder={t('label.self.service.incident.personalDataInvolved.placeholder')}
            />
          </Field>
          <Field>
            <Label label={t('label.self.service.incident.rootCause')} relation='rootCause' />
            <Input
              id='rootCause'
              name='rootCause'
              onChange={handleChangeInput}
              value={formValues.rootCause}
              type='text'
            />
          </Field>
          <Field>
            <Label label={t('label.self.service.incident.field.description')} relation='description' mandatory />
            <Textarea
              relation='description'
              name='description'
              required
              onChange={handleChangeInput}
              value={formValues.description}
            />
          </Field>
          <Field>
            {fileName && fileName.trim().length > 0 && (
              <div className='dsar-file-space'>
                <div className='dsar-filename'>{fileName}</div>
              </div>
            )}
            <div className='dsar-file-space'>
              {fileError && <span className='dsarFileError'>{fileError || ''}</span>}
              <div className='dsar-file-label'>
                <button className='dsar-button-upload' onClick={handleUploadClick}>
                  <i className='fas fa-paperclip' aria-hidden='true' />
                  {t('yourRequests.label.addFile')}
                </button>
                <span className='dsarFileTip'>(JPG, jPEG, PNG e PDF.)</span>
                <input
                  style={{ display: 'none' }}
                  type='file'
                  id={'filea'}
                  ref={inputFile}
                  accept='.pdf,.png,.jpg,.jpeg,image/jpeg,image/png,application/pdf'
                  onChange={changeFieldFile}
                />
              </div>
            </div>
          </Field>

          <Field className='singup-recaptcha'>
            <ReCAPTCHA sitekey={captchakey} onChange={setCaptchaValue} hl={language} key={language} />
          </Field>

          <Button
            type='primary'
            label={t('button.label.send')}
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
          />
        </div>
      </div>

      <Popup open={message} onClose={() => setMessage()} closeOnDocumentClick={true} position='right center' modal>
        {(close) => (
          <div className='popup'>
            <div className='close'>
              <a onClick={close}>&times;</a>
            </div>
            <div>{message}</div>
          </div>
        )}
      </Popup>
    </section>
  )
}

export default IncidentsSelfService
