import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Loading } from 'components/Icons'
import { axiosInstance } from 'services/api'
import UserStore from 'services/userLocalStorage'
import { getPreferences } from 'store/reducers/preference.thunks'
import './styles.sass'

function AssessmentPortal({ match }) {
    const { t } = useTranslation()
    const { auth, geral = {} } = useSelector((store) => store)
    const reduxDispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    const [assessmentListByType, setAssessmentListByType] = useState([])
    const endPoints = window.endPoints
    const history = useHistory()

    const [paginationByType, setPaginationByType] = useState({
        page: 0,
        totalElements: 0,
    })

    let token = ''
    if (auth.signinToken == null) {
        token = UserStore.getToken()
    } else {
        token = auth.signinToken
    }

    useEffect(() => {
        if (auth.isAuthenticated && geral.language) {
            reduxDispatch(getPreferences(geral.language))
        }
        // eslint-disable-next-line
    }, [reduxDispatch, geral.language])

    const sendToConsentPersonRespondent = (item) => {
        setLoading(true)
        setMessage({})

        const requestOptions = {
            method: 'POST',
            url: `/selfservice/sendToConsentPersonRespondent?portalId=${endPoints.api.portalID}`,
            headers: { Authorization: `Bearer ${token}` },
            data: { formEncryptedId: item.encryptedId },
        }

        axiosInstance(requestOptions)
            .then((response) => {
                setLoading(false)
                setMessage({ type: 'success', message: t('edit.success.save') })
                executeAction(response.data)
            })
            .catch((e) => {
                _showError()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const executeAction = (respondentEncryptedId) => {
        history.push(`/portal-form-response/${respondentEncryptedId}`)
    }

    async function loadAssessmentByType() {
        setLoading(true)
        setMessage({})
        const requestOptions = {
            method: 'GET',
            url: `/selfservice/assessment/form/portal?portalId=${endPoints.api.portalID}&sort=title,asc&&page=${paginationByType.page}`,
            headers: { Authorization: `Bearer ${token}` },
        }
        try {
            const {
                data: { content: assessmentListData, totalElements },
            } = await axiosInstance(requestOptions)
            setAssessmentListByType(assessmentListData)
            setPaginationByType((values) => ({ ...values, totalElements }))
        } catch (error) {
            _showError()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadAssessmentByType()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _showError = () => {
        setLoading(false)
        setMessage({ type: 'error', message: t('genericError') })
    }

    return (
        <section>
            <header className='pvt-content-header custom'>
                <h1
                    style={{
                        margin: '0 0 5px',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        color: 'var(--txt-surface)',
                    }}
                >
                    {t('label.self.service.assessment.create')}
                </h1>
                <p className='pvt-content-desc custom'>{t('label.self.service.assessment.description')}</p>
            </header>
            <div className='pvt-card pvt-card-100'>
                {loading ? (
                    <Loading />
                ) : (
                    <div className='dsar-form'>
                        {assessmentListByType.length > 0 ? (
                            <div className='table-responsive'>
                                <table className='table table-striped table-hover col-sm-12'>
                                    <thead>
                                        <tr>
                                            <th>{t('assessment.form.table.title')}</th>
                                            <th>{t('assessment.form.table.description')}</th>
                                            <th>{t('assessment.form.table.action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {assessmentListByType.map((item) => (
                                            <tr key={item.encryptedId}>
                                                <td>{item.title}</td>
                                                <td>{item.description}</td>
                                                <td className='td-buttons'>
                                                    <button
                                                        type='button'
                                                        title={t('assessment.form.table.button.respondent')}
                                                        onClick={() => sendToConsentPersonRespondent(item)}
                                                        className='btn btn-info'
                                                    >
                                                        <i className='fas fa-reply'></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className='pvt-content-desc custom'>{t('assessment.form.assessment.respondent.not.found')}</p>
                        )}
                    </div>
                )}
            </div>
        </section>
    )
}

export default AssessmentPortal