import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import './styles.sass'

// components
import Button from 'components/Button'
import { Row } from 'components/Layout'
import Table from 'components/Table'
import YourRequestForm from './YourRequestForm'
import MessageForm from './MessageForm'
import Status, { h } from './Status'
import NotLogged from 'components/NotLogged'
import { Loading } from 'components/Icons'
import StatusLabel from './StatusLabel'

// store
import { useDispatch, useSelector } from 'react-redux'
import { getCountries } from 'store/reducers/geral.thunks'

import {
  getRequests,
  getRequestsTypes,
  sendRequests,
  sendMessage,
} from 'store/reducers/requests.thunks'

import {
  getPersonalData
} from 'store/reducers/personal-data.thunks';

import { getLanguageCodeByValue } from 'services/availableLanguages'
import useMediaQuery from 'services/useMediaQuery'

const DetailsLink = (request, handleDetailLinkClick) => {
  return (
    <a className="pvt-your-request-link" onClick={(ev) => handleDetailLinkClick(ev, request)}>{request.protocol}</a>
  );
};

function Action(request, handleNewMessageClick, t) {
  if (request.statusCode === 'REPLIED') {
    return <Button
      type="primary"
      label={t('yourRequests.button.sendMessage')}
      size="small"
      classie="lighter"
      disabled={false}
      // icon={<SendMessage />}
      onClick={() => handleNewMessageClick(request)}
    />
  }

  return <span />;
};

function YourRequests() {
  const [newRequest, setNewRequest] = React.useState(false)
  const [newMessage, setNewMessage] = React.useState(false)
  const [selectedRequest, setSelectedRequest] = React.useState(null)
  const { auth = {}, personalData = {}, requests = {}, geral } = useSelector((store) => store)

  const { t } = useTranslation();
  const history = useHistory();
  const reduxDispatch = useDispatch();

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      reduxDispatch(getRequests())
      reduxDispatch(getRequestsTypes())
      reduxDispatch(getPersonalData())
      if (!geral.countries || !geral.countries.length) {
        reduxDispatch(getCountries())
      }
    }
    // eslint-disable-next-line
  }, [geral.language])

  function handleNewRequestClick() {
    setNewRequest(!newRequest)
  }

  function handleRefreshPageClick() {
    //window.location.reload(true);
    reduxDispatch(getRequests());
  }

  function handleNewMessageClick(request) {
    setSelectedRequest(request);
    setNewMessage(!newMessage);
  }

  function submitRequest({ category, message, requestDetail, fileData, fields, userType }, callback, callbackError) {
    const params = {
      requestType: category.value,
      requestDetail,
      message,
      fileData,
      languageCode: getLanguageCodeByValue(geral.language),
      fields,
      userType
    }
    reduxDispatch(sendRequests(params,
      (data) => {
        callback(data)
        reduxDispatch(getRequests())
      },
      (error) => {
        callbackError(error)
      }
    ))
  }

  function submitMessage({ message, fileData }, callback) {
    const params = {
      requestId: selectedRequest?.encryptedId,
      message,
      languageCode: getLanguageCodeByValue(geral.language),
      fileData
    }
    reduxDispatch(sendMessage(params, (data) => {
      if (data.status === 200) {
        // console.log(data)
        callback()
        // Timeout para animação do Ok na tela MessageForm
        setTimeout(() => {
          setNewMessage(false)
          reduxDispatch(getRequests())
        }, 1500)
      } else {
        console.log('Erro: ', data)
      }
      //reduxDispatch(getRequests())
    }))
  }

  const langCode = getLanguageCodeByValue(geral.language)
  const categories = requests?.requestsLabel.length > 0 ?
    requests?.requestsLabel
      .filter((e) => e.language.id === langCode)
      .map(({ label, encryptedId }) => ({
        label,
        value: encryptedId,
      })) : []

  const countries = geral.countries
    .map((c) => ({
      label: geral.language === "en" ?
        c.nameEn
        :
        (geral.language === "es" ? c.nameEs : c.namePt),
      value: c.code,
    }));

  const handleDetailLinkClick = (ev, request) => {
    ev.preventDefault();
    history.push(`/your-requests/${request.protocol}`);

  }

  const verifyPersonData = () => {
    const boolDocument = (geral.portalConfig?.showDocument && geral.portalConfig?.requiredDocument && !personalData?.person?.document)
    const boolPhone = (geral.portalConfig?.showPhoneNumber && geral.portalConfig?.requiredPhoneNumber && !personalData?.person?.phoneNumber)
    const boolCountry = (!personalData?.person?.country && geral.portalConfig?.showCountry)
    const boolName = (!personalData?.person?.name)
    return boolDocument || boolPhone || boolCountry || boolName
  }

  const websiteId = geral.portalConfig.websiteEncryptedId;

  const table = requests?.requests
    ? requests?.requests.map((request) => [
      h(Status, { element: DetailsLink(request, handleDetailLinkClick) }),
      request.requestType,
      request.requestDateFormated,
      request.expirationDate ? request.expirationDate : "",
      h(Status, { element: Action(request, handleNewMessageClick, t) }),
      h(Status, { element: <StatusLabel statusCode={request.statusCode} status={request.status} /> }),
    ])
    : []

  const mobile = useMediaQuery('(max-width: 1000px)')
  if (!auth.isAuthenticated) {
    return (
      <section className="pvt-your-request custom">
        {/* <header className="pvt-content-header custom">
          <h1 className="pvt-content-title custom">{t('yourRequests.title')}</h1>
        </header> */}
        <NotLogged />
      </section>
    )
  }

  if (newMessage && selectedRequest) {
    return (
      <MessageForm
        onSubmit={submitMessage}
        request={selectedRequest}
        onClickBackButton={handleNewMessageClick}
      />
    )
  }

  if (newRequest) {
    return (
      <section className="pvt-your-request custom">
        <header className="pvt-content-header custom">

          <Row position={['y-center', 'x-start']}>
            <button
              type="button"
              className="pvt-btn2 custom"
              disabled={verifyPersonData()}
              onClick={handleNewRequestClick}
            >

              <i className="fas fa-arrow-left" aria-hidden="true"></i>&nbsp;
              <span>{t('button.label.back')}</span>
            </button>
            <h1 className="pvt-content-title custom">{t('yourRequests.title.new.request')}</h1>
          </Row>
        </header>

        <YourRequestForm
          onSubmit={submitRequest}
          categoriesOptions={categories}
          countries={countries}
          websiteId={websiteId}
          isPtBr={geral.language === "pt"}
        />

      </section>
    )
  }

  return (
    <section>
      <header className="pvt-content-header custom" style={{ marginTop: '1px' }}>
        <Row>
          <h1 className="pvt-content-title custom">
            {t('yourRequests.title')}
          </h1>
        </Row>
        <Row >
          <Button
            style={{ right: '200px', position: 'absolute' }}
            className="pvt-btn custom pvt-btn-primary undefined size-undefined"
            type="primary"
            label={t('yourRequests.button.refreshList')}
            onClick={handleRefreshPageClick}
          />
          <Button
            style={{ right: '35px', position: 'absolute' }}
            className="pvt-btn custom pvt-btn-primary undefined size-undefined"
            type="primary"
            label={t('yourRequests.button.newRequest')}
            onClick={handleNewRequestClick}
            disabled={verifyPersonData()}
          />
          {
            verifyPersonData() ?
              <p style={{ fontWeight: "bold", color: "black" }}>{t('yourRequests.button.completeRegistration')}</p>
              :
              ''
          }
        </Row>
      </header>
      {requests.fetching ?
        <p><Loading /> {t('yourRequests.loading')}</p>
        : null
      }
      {!requests.fetching && !table.length ?
        <p>{t('yourRequests.noregister')}</p>
        : null
      }

      {!!table.length && (
        mobile ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>

            {table.map(linha => (
              <div style={{ background: '#FFF', border: '1px solid #f0f0f0', borderRadius: 5, padding: '10px 15px', paddingBottom: 50, position: 'relative' }}>

                <div>Protocolo: {linha[0]?.props?.element}</div>
                <div>Tipo: {linha[1]}</div>
                <div>Data: {linha[2]}</div>
                {linha[3] && <div>Prazo: {linha[3]}</div>}
                <div>{linha[4]?.props?.element}</div>
                <div style={{ position: 'absolute', right: 10, bottom: 15 }}>{linha[5]?.props?.element}</div>
              </div>
            ))}

          </div>
        ) : (
          <Table
            className="pvt-table your-requests-table pvt-card pvt-txt-card pvt-card-100 custom"
            data={table}
            columns={[
              {
                name: t('yourRequests.table.protocol'),
                width: '17%'
              },
              {
                name: t('yourRequests.table.requestType'),
                width: '23%'
              },
              {
                name: t('yourRequests.table.date'),
                width: '10%'
              },
              {
                name: t('yourRequests.table.prediction.response'),
                width: '15%'
              },
              {
                name: t('yourRequests.table.actions'),
                width: '8%'
              },
              {
                name: t('yourRequests.table.status'),
                width: '20%'
              }]}
          />
        )
      )}
    </section>
  )
}

export default YourRequests;